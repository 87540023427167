.payment-methods {
  margin-top: 90px;
  margin-bottom: 50px;
}
.payment-methods .payment-progress {
  padding: 75px 0;
  text-align: center;
}
.payment-methods .payment-title {
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.payment-process {
  min-height: 355px;
  background-color: #c3e3de;
  border-radius: 10px;
  position: relative;
  margin-bottom: 25px;
}
.payment-overlay {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background-image: url("../../assets/payment/card_back.jpg");
  z-index: 999;
  top: 0;
  left: 0;
  padding: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.payment-process .payment-overlay .card-heading {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.payment-process .payment-overlay .card-heading img {
  width: 75px;
  height: 75px;
}
.payment-process .payment-overlay .card-heading span {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
}
.payment-process .payment-overlay p {
  font-size: 18px;
  color: #393939;
  letter-spacing: 1px;
  line-height: 1.7;
  text-transform: capitalize;
  font-family: sans-serif;
  margin-bottom: 45px;
}
.payment-process .payment-overlay button {
  width: 100%;
  height: 45px;
  background-color: #2d2c79;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-bottom: 15px;
}
.payment-process .payment-overlay h5 {
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  cursor: pointer;
}
/* edit e_wallet payment radio button */
.radio-btns .form-check .form-check-input {
  width: 25px;
  height: 25px;
  border: 2px solid #9a9b9a;
}
.radio-btns .form-check .form-check-input:checked {
  border: 2px solid #2d2c79;
  background-color: #2d2c79;
  outline: none;
  -webkit-print-color-adjust: none;
}
.radio-btns .form-check .form-check-label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  text-transform: capitalize;
}
.radio-btns .form-check {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 768px) {
  .payment-progress img {
    width: 100%;
  }
}
@media (max-width: 525px) {
  .payment-methods .payment-title {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .payment-process .payment-overlay p {
    font-size: 16px;
    margin-bottom: 25px;
  }
}
