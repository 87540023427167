.language-selector,
.currency-selector {
  color: rgba(58, 20, 78, 1) !important;
  padding: 10px 0;
  font-weight: bold;
  /* font-size: 18px; */
  text-transform: uppercase;
}
.css-mrogtp-option {
  color: #fff !important;
}
/* loader spinner */
.loader-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.loader {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #514b82;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 70deg,
      #0000 71deg 90deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l5 1s infinite;
}
@keyframes l5 {
  to {
    transform: rotate(0.5turn);
  }
}
/* buttons */
.signin-btn {
  padding: 10px 0;
  background-color: #2d2c79;
  color: white;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
}
.discard {
  background-color: transparent;
  color: #2d2c79;
}
.modal.show .modal-dialog {
  margin: auto !important;
}
.modal-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #2d2c79;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.merchant-img {
  /* height: 250px; */
  border-radius: 15px;
}
.heart {
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  width: 35px;
  height: 35px;
  padding: 5px 8px;
  color: #c2bfbf;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  z-index: 99;
}
.modal-textarea {
  width: 100%;
  height: 210px;
  border-radius: 10px;
  margin-bottom: 15px;
  resize: none;
  font-weight: bold;
  font-size: 17px;
  padding: 10px;
  border: 2px solid #dcdddc;
  text-align: end;
}
.action-list {
  height: auto;
  display: none;
  padding: 15px !important;
  position: absolute;
  top: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
  z-index: 999;
}
.action-list li {
  display: flex;
  align-items: center;
  gap: 5px;
  list-style: none;
  margin-bottom: 10px;
  color: var(--new-1, #2d2c79);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.show-action-list {
  display: block;
}
.form-switch .form-check-input {
  cursor: pointer;
  height: 30px;
  width: 65px;
}
.form-switch .form-check-input:checked {
  background-color: #2d2c79;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}
/* merchant overlay */
.merchant-overlay {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 25px;
}

.merchant-overlay img {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
}
.merchant-overlay h5 {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25px;
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 1px;
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.empty-products {
  font-weight: bold;
  font-size: 20px;
  color: red;
  font-family: monospace;
}

.product-merchant {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: sans-serif;
}
.product-merchant .name {
  color: #2d2c79;
  font-family: cursive;
}
.product-merchant .name img {
  margin-right: 5px;
}
.fill-star {
  width: 25px;
  height: 25px;
  color: #ffcc00;
}
.empty-star {
  width: 25px;
  height: 25px;
}
.rating {
  width: 125px;
}
.product-rating .empty-star {
  color: white;
}
.accordion-item .accordion-header .accordion-button {
  justify-content: space-between !important;
}
.accordion-button::after {
  margin: inherit !important;
}

.pagination-text {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: monospace;
}

.css-19bb58m {
  display: none !important;
}

.invoice-modal .modal-dialog .modal-content {
  width: fit-content !important;
  text-wrap: nowrap;
}

.add-ticket input,
.add-ticket textarea {
  background-color: rgba(217, 217, 217, 0.23) !important;
}
