.myGeneralSwiper{
     /* height: 75vh;
    margin-top: 80px;
    background-color: aliceblue; */
    background-image: url("../../assets/forms_background.png");
   background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 45vh;
    margin-top: 80px;
  
    width: 100%;
    position: relative;
}
.myGeneralSwiper .swiper-pagination-bullet{
    width: 20px;
    height: 20px;
    background-color: #fff;
    opacity: 0.6;
}
.myGeneralSwiper .swiper-pagination-bullet-active{
    background-color: #fff;
    border-radius: 10px;
    opacity: 1;
}
.myGeneralSwiper .swiper-slide .overlay{
    background-image: url("../../assets/black_overlay.png");
   /*  background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    padding: 0 0 0 50px; */
    /* height: 45vh; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 56.25%; /* This maintains a 16:9 aspect ratio */
    position: relative;
}

@media (max-width: 500px) {
    .mySwiper .swiper-slide {
      height: auto; 
    }
    .mySwiper{
      height:auto;
    }
  }