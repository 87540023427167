.bankCard-heading{
    height: 115px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #75D5C9;
    border-radius: 10px;
    margin-bottom: 25px;
}
.bankCard-heading .bankCard-img{
    display: flex;
    align-items: center;
    gap: 10px;
}
.bankCard-heading .bankCard-img img{
    width: 75px;
    height: 75px;
}
.bankCard-heading .bankCard-img span{
    font-size: 22px;
    letter-spacing: 1px;
}