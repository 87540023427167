.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 25px; */
}
.heading .heading-text {
  font-size: 28px;
  font-weight: bold;
}
.heading .heading-subtext {
  color: #2d2c79;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
@media (max-width: 525px) {
  .heading .heading-text {
    font-size: 16px;
  }
  .heading .heading-subtext {
    font-size: 14px;
  }
}
