.merchant-heading{
    margin-top: 100px;
    padding: 50px 0;
}

.merchant-game{
    background-image: url("../../assets/top_merchant/merchant_game.png");
}
.merchant-game .overlay .star{
    justify-content: space-between;
    gap: 0;
}
.merchant-game .game-name{
    width: 85%;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: bold;
    position: absolute;
    left: 10px;
    top: 10px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 999;
}

