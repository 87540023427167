.mySwiper {
  /* height: 45vh; */
  margin-top: 80px;

  width: 100%;
  position: relative;
   img {
    width: 100%;       
    height: auto;  
    object-fit: cover;}
}
.mySwiper .swiper-slide {
  /* height: 45vh; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding-top:1rem;
  /* padding-top: 56.25%; This maintains a 16:9 aspect ratio */
  position: relative;
}

.mySwiper .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background-color: #fff;
  opacity: 1;
}
.mySwiper .swiper-pagination-bullet-active {
  background-color: #75d5c9;
  width: 88px;
  border-radius: 10px;
}
.mySwiper .swiper-slide .overlay {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  padding: 0 50px 0 50px;
}
.mySwiper .swiper-slide .overlay h1 {
  color: #fff;
  font-size: 56px;
  font-weight: bold;
}
.mySwiper .swiper-slide .overlay h1 span {
  color: #75d5c9;
}

.mySwiper .swiper-slide .overlay p {
  /* width: 50%; */
  font-size: 24px;
  color: #fff;
  line-height: 1.8;
}
.mySwiper .swiper-slide .overlay button {
  background-color: #75d5c9;
  border: 0;
  outline: 0;
  color: #fff;
  width: 184px;
  height: 54px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .mySwiper .swiper-slide .overlay h1 {
    font-size: 45px;
  }
  .mySwiper .swiper-slide .overlay p {
    width: 100%;
  }
}
@media (max-width: 525px) {
  .mySwiper .swiper-slide .overlay {
    padding: 0 30px;
  }
  .mySwiper .swiper-slide .overlay h1 {
    font-size: 22px;
    line-height: 1.6;
  }
  .mySwiper .swiper-slide .overlay p {
    width: 100%;
    font-size: 15px;
  }
}
@media (max-width: 500px) {
  .mySwiper .swiper-slide {
    height: auto; 
  }
  .mySwiper{
    height:auto;
  }
}


