.currency-selector {
  padding: 10px 0;
}
.currency-flag {
  width: 25px;
  height: 25px;
  margin: 0 5px;
  border-radius: 50%;
}

.currency-option {
  display: flex;
  align-items: center;
  width: 100%;
}

.currency-single-value {
  display: flex;
  align-items: center;
}

.css-qbdosj-Input {
  display: none !important;
}
.css-13cymwt-control {
  background-color: transparent !important;
  padding: 0 !important;
  border: 0 !important;
}
