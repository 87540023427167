.product-details .merchant-heading {
  padding: 0;
  margin: 0;
}
.product-details .merchant-heading .heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.product-details .merchant-heading .heading h1 {
  font-size: 25px;
  font-weight: bold;
}
.product-details .merchant-heading .heading .price {
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
}
.product-details .merchant-heading .heading .price .old-new .old-price {
  text-decoration: line-through;
  color: #2d2c79;
}
.product-details .merchant-heading .heading .price .old-new .new-price {
  color: red;
}
.product-details .merchant-actions-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-details .merchant-actions button {
  height: 50px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #2d2c79;
  color: white;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: system-ui;
  padding: 0 25px;
  /* margin-right: 20px; */
}
.product-details > img {
  height: 625px;
  border-radius: 5px;
}
.product-details .tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 15px;
}
.product-details .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: black;
  font-size: 18px;
  font-weight: bold;
  padding: 0;
}
.product-details .nav-pills .nav-link {
  color: #9a9b9a;
  font-size: 18px;
  font-weight: bold;
}
.product-details .tab-content p {
  font-size: 16px;
  color: #1c2a53;
  line-height: 1.8;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-family: "Poppins";
  margin-bottom: 25px;
}
.product-details .add-review {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  color: #2d2c79;
  font-size: 16px;
  font-weight: bold;
  font-family: "poppins";
  letter-spacing: 1px;
  margin-bottom: 25px;
}
.product-details .add-review .read {
  cursor: pointer;
  text-decoration: underline;
}
.product-details .add-review .add {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* special edit */
.product-details .merchant-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* position product img and heart */
.productDetails-img {
  position: relative;
}
.productDetails-img img {
  max-height: 400px;
  /* border: 1px solid gray; */
  border-radius: 10px;
}
.productDetails-img .heart {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
}
@media (max-width: 525px) {
  .product-details .merchant-heading .heading {
    flex-direction: column;
    margin: 0 !important;
  }
  .product-details .merchant-heading .heading .price {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .product-details > img {
    height: 250px;
  }
  .product-details .merchant-heading .heading h1 {
    font-size: 25px;
  }
  .merchant-heading p {
    font-size: 16px;
  }
  .rating-count .rating-count-info p {
    text-wrap: nowrap;
  }
}
