.payment-instructions h1,
.payment-result h2 {
  font-size: 18px;
  font-weight: bold;
  font-family: sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 35px;
}
.payment-result .qr_img {
  width: 207px;
  height: 207px;
}
.payment-result .pay-code {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  font-weight: bold;
  color: rgba(45, 44, 121, 1);
}
.payment-result .pay-code .copy-code {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #ebf0f7;
  background: #fafafa;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0 15px;
}
