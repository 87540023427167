@import url("https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Playpen+Sans:wght@100..800&display=swap");

.top-sale {
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  margin: 0 0px 50px 0;
}
.top-sale .top-game {
  position: relative;
  width: 100%;
  height: 200px;
}
.top-sale .top-game > img {
  border-radius: 15px;
}
.top-sale .top-game .overlay {
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/popular/overlay.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  display: flex;
}
.top-sale .top-game .overlay-2 {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 44, 121, 0.8);
  padding: 10px;
}
.top-sale .top-game:hover .overlay-2 {
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 0.8s;
}

.top-sale .top-game .overlay p {
  font-size: 18px;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
  color: white;
  margin-bottom: 15px;
}
.top-sale .top-game .overlay .discount .discount-text {
  font-size: 30px;
  margin-right: 6px;
  font-weight: bold;
  color: red;
}
.top-sale .top-game .overlay .discount .discount-off {
  font-size: 16px;
  font-weight: bold;
  color: darkgray;
}
.top-sale .top-game .overlay .game-price {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: "Playpen Sans", cursive;
  color: #2d2c79;
  font-size: 18px;
  font-weight: bold;
}
