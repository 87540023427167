.popular-game {
  width: 100%;
  height: 315px;
  background-image: url("../../assets/popular/game_1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 25px;
}
.popular-game .overlay {
  position: absolute;
  display: flex;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background-image: url("../../assets/popular/overlay.png");
  padding: 20px 15px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.popular-game .new-price {
  color: red;
}
.popular-game .discount {
  width: 90%;
  position: absolute;
  left: 10px;
  top: 10px;
  background-image: url("../../assets/popular/discount_box.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 5px;
  width: 70px;
  font-weight: bold;
  font-family: cursive;
  z-index: 999;
}
.popular-game .heart {
  position: absolute;
  top: 10px;
}
.popular-game .overlay .star {
  display: flex;
  align-items: center;
  gap: 70px;
  color: white;
  font-weight: bold;
  font-size: 18px;
}
.popular-game .overlay .star a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-transform: capitalize;
}
.popular-game .overlay .star a:hover {
  color: blue;
  text-decoration: underline;
  transition: all 0.4s ease-in-out;
}
.popular-game .overlay p {
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 15px;
}
.popular-game .overlay button {
  background-color: #2d2c79;
  width: 100%;
  color: #fff;
  font-weight: bold;
  outline: none;
  border: 0;
  padding: 10px 0;
  border-radius: 5px;
}
