.profile-side-bar {
  width: fit-content;
  height: fit-content;
  margin: 100px 0px 15px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(56, 71, 109, 0.09);
}
.profile-side-bar ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  padding: 25px 0 !important;
}
.profile-side-bar ul a {
  text-decoration: none;
  padding: 10px 15px;
  width: 100%;
}
ul a.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #75d5c9;
}
ul a.active {
  background-color: rgba(117, 213, 201, 0.52);
  padding: 10px 15px;
  position: relative;
  margin-left: 0;
}
.li-title .title {
  font-size: 16px;
  font-weight: bold;
  color: #2d2c79;
  display: block;
  /* text-wrap: nowrap; */
}
.li-title span {
  font-size: 12px;
  font-weight: bold;
  color: #5e5f5e;
}
@media (max-width: 768px) {
  .li-title {
    display: none !important;
  }
  .profile-side-bar ul a {
    justify-content: center;
  }
}
