.payment-methods .pay-con{
    background-color: #FAFAFA;
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 50px 30px;
    border-radius: 10px;
}
.payment-methods .pay-con .pay-con-details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.payment-methods .pay-con .pay-con-details p{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #00564E;
    letter-spacing: 1px;
    text-transform: capitalize;
}