.signin-form {
  background-color: rgba(255, 255, 255, 0.94);
  border-radius: 23px;
  padding: 15px 25px;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE/Edge */
}
.signup-form {
  max-height: 92%;
}
.text-danger {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
  text-transform: capitalize;
}
.signin-form div::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.signin-form .user-info-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.signin-form input[type="number"]::-webkit-inner-spin-button,
.signin-form input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.signin-form .pass_hidden {
  width: 25px;
  height: 25px;
  margin: 10px;
}
.signin-form .input-group label {
  font-size: 16px;
  font-weight: 600;
  color: #3c3c48;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: block;
}
.signin-form .input-group {
  flex-direction: column;
}
.form-input {
  background-color: rgba(217, 217, 217, 0.23);
  display: flex;
  align-items: center;
  border: 1px solid #3c3c48;
  border-radius: 5px !important;
  padding: 0px 0px 0 7px;
  overflow: hidden;
}
.country-code-dropdown {
  width: 20%;
  height: 43px;
  border-radius: 5px !important;
}
.select__control {
  height: 100% !important;
  border: 1px solid #3c3c48 !important;
}

.form-input input {
  width: 100%;
  padding: 10px 0 10px 5px;
  border-radius: 0px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  color: #3c3c48;
  font-weight: bold;
  letter-spacing: 1px;
}
.check-forget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.check-forget .check-box {
  display: flex;
  align-items: center;
  gap: 5px;
}
.check-box .checkbox-inp {
  display: flex;
  gap: 5px;
  align-items: center;
}
.check-forget .check-box input {
  width: 20px;
  height: 20px;
}
.check-forget .check-box:checked {
  background-color: #54a99f;
}
.check-box span {
  color: #54a99f;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.check-forget .check-box label,
.have-account span {
  color: #3c3c48;
  font-size: 14px;
  font-weight: bold;
}
.check-forget .forget-password a,
.signin-form p,
.have-account a {
  color: #54a99f;
  font-size: 14px;
  font-weight: bold;
}
.signin-form .form-social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
}
.signin-form .form-social img {
  width: 50px;
  height: 50px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}
@media (max-width: 525px) {
  .signin-form .user-info-group {
    flex-direction: column;
    gap: 0;
  }
  .check-forget .check-box {
    flex-direction: column;
    align-items: self-start;
  }
}
