.slider-container {
  overflow: hidden;
}
.slick-initialized .slick-slide {
  padding: 0 10px;
}
.recomended-box {
  padding: 10px 0;
  display: flex !important;
  align-items: center;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 5px 5px 5px 0px;
  position: relative;
  outline: none;
  border-radius: 5px;
}
.recomended-box > .heart {
  position: absolute;
  top: 10px;
}
.recomended-box .recommend-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.recomended-box .recomended-details {
  padding: 0 7px;
  width: 100%;
}
.recomended-box .recomended-details a {
  display: inline-block;
  font-size: 15px;
  color: #2d2c79;
  text-decoration: none;
  font-weight: bold;
  text-transform: capitalize;
}
.recomended-box .recomended-details a:hover {
  color: blue;
  text-decoration: underline;
}
.recomended-box .recomended-star {
  display: flex !important;
}
.recomended-star span {
  font-size: 12px;
  color: #aeb1c1;
  font-weight: bold;
  margin: 0 5px;
}
.recomended-box .recomended-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recomended-box .recomended-btn span {
  color: #2d2c79;
  font-size: 18px;
  font-weight: bold;
  display: block;
}
.recomended-box .buy-now {
  color: white;
  background-color: #2d2c79;
  border: 0;
  outline: none;
  border-radius: 5px;
  padding: 5px 15px;
}
@media (max-width: 425px) {
  .recomended-box {
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    margin-bottom: 15px;
  }
}
