.profile-img {
  width: fit-content;
  position: relative;
}
.profile-img .img {
  width: 160px;
  height: 160px;
  border-radius: 10px;
}
.img-edit {
  position: absolute;
  top: 65%;
}
.img-edit img {
  cursor: pointer;
}
.img-edit span {
  color: red;
  font-weight: bold;
  cursor: pointer;
}
.user-transactions label {
  font-weight: 400;
  margin-bottom: 10px;
}
/* #525352 */
.user-profile input[type="text"],
.user-profile input[type="email"],
.user-profile input[type="tel"],
.user-profile input[type="password"],
.user-profile input[type="date"],
.user-profile input[type="file"],
.user-profile select,
.user-profile textarea {
  width: 100%;
  border: 0;
  outline: none;
  color: #525352;
  padding: 15px;
  font-weight: bold;
  border-radius: 6px;
}
.security-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 25px;
}
.security-btns button {
  width: 125px !important;
}
