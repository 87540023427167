.faq-item svg {
  width: 45px;
  height: 45px;
  background-color: #75d5c9;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 10px;
  color: rgba(45, 44, 121, 1);
}

.faq-item h2 {
  color: #2d2c79;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  font-family: sans-serif;
  margin-bottom: 15px;
}

.faq-item p {
  color: #53686a;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
}
