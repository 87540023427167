.rating-count {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  padding: 50px 25px;
}
.rating-count .rating-count-info h3 {
  font-size: 24px;
  font-weight: bold;
}
.rating-count .rating-count-info p {
  color: #8898aa;
  font-size: 16px;
  font-weight: bold;
}
.rating-stars {
  width: 50%;
}
.rating-stars .stars {
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 15px;
}
.rating-stars .stars span:first-child {
  display: flex;
}
.rating-stars .stars span:nth-child(2) {
  font-size: 16px;
  font-weight: bold;
}
.rating-stars .rating-progress-section {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background-color: #f1f1f1;
}
.rating-stars .rating-progress {
  position: absolute;
  height: 100%;
  top: 0;
  background-color: #ffcc00;
  border-radius: 20px;
}
@media (max-width: 525px) {
  .reviews-heading-section {
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 15px;
    align-items: flex-start;
  }
  .reviews-heading-section .rating-stars {
    width: 100%;
  }
  .player-text p {
    text-align: center;
  }
  .product-details .merchant-actions-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .product-merchant {
    font-size: 25px !important;
    width: 100%;
  }
  .product-details .merchant-actions {
    width: 100%;
  }
  .product-details .merchant-actions button {
    width: 100%;
  }
  .rating-count {
    /* flex-direction: column !important; */
    padding: 0 !important;
  }
  .product-details .add-review {
    padding: 0 !important;
  }
  .mobile-view {
    flex-direction: row;
  }
}
