.user-transactions {
  margin-top: 90px;
  padding-top: 25px;
  min-height: 65vh;
}

.user-transactions h1 {
  color: rgb(45, 44, 121);
  font-size: 20px;
  font-weight: bold;
}
.user-transactions h3 {
  color: rgb(45, 44, 121);
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* search */
.DocSearch-Form {
  border: 1px solid #333333;
  border-radius: 6px;
  height: 43px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
}
.DocSearch-MagnifierLabel,
.DocSearch-Reset {
  align-items: center;
  display: flex;
  justify-content: center;
}
.DocSearch-Input {
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--docsearch-text-color);
  flex: 1;
  font: inherit;
  font-size: 1.2em;
  height: 100%;
  outline: none;
  padding: 0 0 0 8px;
  width: 80%;
}
/* table */
.user-transactions table thead tr {
  background-color: #f5f8fa;
}
.user-transactions table thead tr th {
  color: #3f4254;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  /* padding: 10px 45px; */
  text-align: center;
}
.user-transactions table tbody tr td {
  color: #7e8299;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 10px;
  text-align: center;
}
.user-transactions table tbody tr td .status {
  display: inline-block;
  height: 32px;
  width: 72px;
  border-radius: 6px;
  font-size: 12px;
  /* text-align: center; */
  line-height: 32px;
}
.user-transactions table tbody tr td .download-btn {
  /* background-color: #F3F3F3; */
  color: #2d2c79;
  font-size: 12px;
  display: inline-block;
  width: 74px;
  height: 27px;
  border-radius: 6px;
  /* text-align: center; */
  line-height: 27px;
  text-decoration: none;
}
/* select box */
.user-transactions .form-select,
.user-transactions .input {
  border: 1px solid #2d2c79;
  font-size: 14px;
  color: #2d2c79;
  font-weight: bold;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.form-select {
  appearance: auto !important;
  background-image: none !important;
}
/* pagination controls */
.pag-btn {
  color: #fff;
  background-color: #2d2c79;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  padding: 10px 15px;
}
.pag-text {
  font-size: 20px;
  color: #2d2c79;
  font-weight: bold;
}
.pag-icon {
  cursor: pointer;
  font-size: 1.7rem;
}
#actions{
  text-align: left;
}
