.merchant-section::-webkit-scrollbar,
.payment-img::-webkit-scrollbar {
  display: none;
}

/* .merchant-img,.payment-img{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
} */
/* .merchant-section::-webkit-scrollbar {
    display: none;
  } */
.merchant-img img {
  width: 100%;
  border-radius: 10px;
}
/* .payment-img img{
    width: 350px;
    height: 146px;
} */
