.user-invoice{
    background-color: #F5F5F5;
    padding: 25px 0px;
}
.user-invoice .logo{
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.user-invoice .logo-info h1{
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #2D2C79;
    margin-bottom: 5px;
}
.user-invoice .logo-info p{
    margin-bottom: 0;
    font-size: 10px;
    font-weight: bold;
    color: #5E6470;
    margin-bottom: 3px;
}
.user-invoice .user-invoice-details{
    border: 1px solid #D7DAE0;
    border-radius: 12px;
    background-color: #fff;
    padding: 25px 10px;
}
.user-invoice .user-invoice-details .download-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-invoice .user-invoice-details .download-btn span{
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.user-invoice .user-invoice-details .download-btn button{
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    background-color: #2D2C79;
    padding: 10px 15px;
    border-radius: 10px;
}
.user-invoice .terms p{
    color: #5E6470;
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
}
/* invoice details */
.user-invoice-details .invoice h6{
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 5px;
    color: #5E6470;
}
.user-invoice-details .invoice p{
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 0px;
}
.user-invoice-details .invoice span{
    color: #5E6470;
    font-size: 15px;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.user-invoice-details .invoice .amount-num{
    color: #2D2C79;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 1px;
}
/* table */
.user-invoice-details .table td h4{
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
}
.user-invoice-details .table td span{
    line-height: 50px;
    font-weight: bold;
    font-size: 15px;
}
.user-invoice-details .table td p{
    font-size: 12px;
    font-weight: bold;
    color: #5E6470;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.invoice-total .subtotal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.hidden{
    display: none;
}