.modal.fade .modal-dialog{
    height: 100%;
}
.modal-content{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.modal-header{
    margin-bottom: 25px;
}
.modal-header,.modal-body{
    border-bottom: 0 !important;
}
.modal-footer{
    border-top: 0 !important;
}
.modal-title{
    color: #2D2C79 !important;
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: system-ui;
}
.modal-body span{
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: system-ui;
    display: inline-block;
    margin-right: 10px;
}
.modal-body input{
    width: 100%;
    height: 60px;
    padding: 0 15px;
    border: 1px solid #7E8299;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
}
.modal-footer button{
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #2D2C79;
    color: white;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: system-ui;
}