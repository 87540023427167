.merchant-heading p {
  color: #1c2a53;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-align: justify;
}
.merchant-heading .player-btn {
  background-color: #75d5c9;
  border: 0;
  outline: 0;
  color: #1c2a53;
  height: 53px;
  width: 285px;
  font-size: 22px;
  font-family: monospace;
  border-radius: 5px;
}
