.discounts {
  background-image: url("../../assets/popular/discount_box.png");
  position: absolute;
  top: 5px;
  left: 10px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  z-index: 999;
  font-family: cursive;
}
.merchant-game .merchant-description {
  height: 50px;
  overflow-y: scroll;
}
.merchant-game .merchant-description::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.merchant-game .merchant-item-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
