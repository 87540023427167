.player-review {
  position: relative;
  /* border-top: 1px solid #E2E2E2; */
  padding-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 25px 25px 0 25px;
}
.player-review .edit {
  position: absolute;
  top: 15px;
  cursor: pointer;
}
.player-info {
  max-width: 125px;
}
.player-info h2 {
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.player-text .star {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.player-text .star span {
  font-weight: bold;
  color: #8898aa;
}
.player-text p {
  font-size: 14px;
  font-weight: bold;
  color: #8898aa;
  letter-spacing: 1px;
  text-transform: capitalize;
  line-height: 1.8;
  font-family: sans-serif;
}
.reviews-section .heading .heading-text {
  color: #2d2c79;
}
.accordion-section .heading .heading-text {
  color: #2d2c79;
}
@media (max-width: 525px) {
  .player-review {
    flex-direction: column;
  }
}
