.inst-section{
    height: 262px;
    background-color: #EBF0F7;
    margin-bottom: 15px;
    border-radius: 16px;
    padding: 20px 30px;
}
.inst-section span{
    width: 40px;
    height: 40px;
    display: block;
    border: 1px solid #2D2C79;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    font-family: monospace;
    color: #2D2C79;
}
.inst-section h3{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.inst-section p,.payment-result p{
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: 1px;
}