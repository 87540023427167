.nav-btns {
  padding: 15px;
  display: flex;
}

.nav-btns a {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #9a9b9a;
  letter-spacing: 1px;
  font-family: cursive;
  padding: 8px 20px;
  justify-content: center;
}

.nav-btns a.active {
  border-radius: 6px;
  background: #2d2c79;
  color: white;
  padding: 8px 16px;
  justify-content: center;
  /* Light/Gray/Gray - SM */
  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
}

/* privacy policy */
.privacy-policy div {
  margin-bottom: 45px;
  text-transform: capitalize;
}
.privacy-policy ul {
  margin: 0 20px;
}
.privacy-policy ul li {
  list-style: outside;
  font-weight: bold;
  font-size: 16px;
  color: grey;
  margin-bottom: 15px;
}
.privacy-policy span {
  color: #5e6278;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  display: block;
}

.privacy-policy p {
  color: #5e6278;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.8;
}
