.paymentCardProduct{
    border: 1px solid #F3F3F3;
    border-radius: 15px;
}
.paymentCardProduct img {
    height: 175px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.paymentCardProduct .paymentCardProductDetails{
    padding: 0px 10px;
}
.paymentCardProduct .product-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.paymentCardProduct .paymentCardProductDetails .vip{
    font-size: 17px;
    font-weight: bold;
    display: block;
}
.paymentCardProduct .paymentCardProductDetails .vip-sub{
    font-size: 17px;
    font-weight: bold;
    color: #5E6278;
    text-transform: capitalize;
    display: block;
}
.paymentCardProduct .paymentCardProductDetails p{
    color: #1C2A53;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-family: sans-serif;
    font-weight: 500;
    line-height: 1.7;
}
.paymentCardProduct .paymentCardProductDetails button{
    height: 53px;
    background-color: #2D2C79;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 5px;
    outline: none;
    border: none;
}