.signin-form .check{
    width: 65px;
    height: 65px;
    margin: 20px 0;
}
.signin-form .success-text,.signin-form .failed-text{
    font-size: 25px;
    font-weight: 600;
}
.signin-form .success-text{color: #2D2C79;text-align: center;}
.signin-form .failed-text{color:#E21E1E}

.signin-form .success-link,.signin-form .failed-link{
    font-size: 17px;
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: none;
    margin-bottom: 25px;
    display: block;
}
.signin-form .success-link{color: #75D5C9;}
.signin-form .failed-link{color: #2D2C79;}