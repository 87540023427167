.filter-box {
  stroke: #e2e5e7;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 17px;
}
.filter-box h1 {
  font-size: 17px;
  font-weight: bold;
  color: #2d2c79;
  margin: 10px 0 15px 0;
}
.filter-box .accordion-button {
  background-color: transparent !important;
  border-radius: 0;
  padding: 10px 10px !important;
  margin-bottom: 0 !important;
  outline: none;
}
.accordion-button:not(.collapsed) {
  color: black !important;
}
.filter-box .signin-btn {
  width: 90%;
  height: 50px;
  margin: auto;
  display: block;
}
