.resend-email .verify-text {
  font-size: 20px;
  font-weight: 600;
  color: #2d2c79;
  text-align: left;
  text-transform: capitalize;
  line-height: 1.6;
}
.resend-email .resend-email {
  color: #0000ee;
  border: 0;
  border-radius: 10px;
  outline: 0;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
  font-family: sans-serif;
}
.resend-email .close {
  position: absolute;
  right: 10px;
  top: 0;
  font-weight: bold;
  font-size: 25px;
  color: #2d2c79;
  cursor: pointer;
}
