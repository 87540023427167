.language-flag {
  width: 25px;
  height: 25px;
  margin: 0 5px;
  border-radius: 50%;
}

.language-option {
  display: flex;
  align-items: center;
}

.language-single-value {
  display: flex;
  align-items: center;
}
