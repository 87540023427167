.navbar-expand-lg .navbar-nav {
  gap: 10px;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}
.notify-container {
  display: flex;
  align-items: center;
}
.notify-container #nav-dropdown-dark-example {
  color: #2d2c79;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 0 5px;
}
/* search box */
.search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;
}
.input-wrapper {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  display: flex;
  align-items: center;
}
.input-wrapper input {
  background-color: transparent;
  border: none;
  outline: none;
  height: 100%;
  font-size: 1rem;
  width: 100%;
  margin-left: 5px;
  font-weight: bold;
}
.results-list {
  /* padding: 10px 15px; */
  position: absolute;
  top: 83px;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
  max-height: 415px;
  overflow: hidden;
}
.results-list div:hover {
  transition: all 0.3s ease-in-out;
  background-color: #efefef;
  color: #2d2c79;
  cursor: pointer;
}
/* .search-dropdown {
  width: fit-content;
}
.search-dropdown .input-group {
  height: 100%;
}
.search-dropdown #nav-dropdown-dark-example {
  background: #75d5c9 !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
}
.search-dropdown .input-group .input-group-text {
  padding: 0px 0 0px 10px;
  background-color: #e2e5e7;
  color: #4b465c;
  border-radius: 0;
  border: 0px transparent solid !important;
}
.search-dropdown
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  background-color: #e2e5e7;
  color: #4b465c;
  border: 0px solid transparent;
  padding-left: 5px;
  outline: none;
  font-weight: bold;
}
.search-dropdown
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback):focus {
  box-shadow: none;
} */

/* dropdown menu */
.navbar-expand-lg .navbar-nav .dropdown-menu {
  background-color: white;
  margin-top: 25px;
  margin-left: -30px;
}
.dropdown-item {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  margin: 0 0 10px 0;
  color: #2d2c79 !important;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: bold !important;
  letter-spacing: 1px;
}
.dropdown-item a {
  text-decoration: none;
  color: #2d2c79;
  display: flex;
  align-items: center;
  gap: 10px;
}
/* sign btn */
.sign-btn {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 25px;
}
.sign-btn a {
  text-decoration: none;
  color: #2d2c79;
  font-weight: bold;
}
.sign-btn .signin-btn {
  padding: 0;
  display: block;
  width: 94px;
  height: 37px;
  line-height: 35px;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    #75d5c9 0%,
    #2d2c79 40%
  );
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
}
