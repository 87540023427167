.signin-form h1 {
  margin: 20px 0;
  font-size: 27px;
  color: #1e1e1e;
  font-family: sans-serif;
  text-align: center;
  letter-spacing: 1px;
  font-weight: bold;
}
.signin-form h6 {
  font-size: 14px;
  text-align: center;
  color: #3c3c48;
  text-transform: capitalize;
  line-height: 1.8;
}
@media (max-width: 525px) {
  .signin-form h1 {
    font-size: 20px;
  }
  .signin-form h6 {
    text-align: center !important;
    margin-bottom: 10px !important;
  }
}
