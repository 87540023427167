.footer-section{
    background-color: #2D2C79;
    padding-top: 50px;
    color: #fff;
}
.footer-section ul li{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
    cursor: pointer;
}
.footer-section .footer-social{
    display: flex;
    align-items: center;
    gap: 15px;
}
.footer-section .footer-copy{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 25px 0 35px 0;
    font-size: 16px;
}
@media (max-width:525px) {
    .footer-section .footer-copy{
        font-size: 10px;
    }
}