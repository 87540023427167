.auth-layout {
  height: 100vh;
  position: relative;
  background-image: url("../../assets/forms_background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px 10px;
}
.auth-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../assets/forms_overlay.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-layout .language-selector {
  position: relative;
  z-index: 9999;
  color: #fff;
  border-radius: 5px;
  width: fit-content;
}
.auth-layout .language-selector .css-13cymwt-control {
  border: none !important;
  background-color: rgba(255, 255, 255, 1) !important;
  border-radius: 100px;
  color: rgba(38, 39, 141, 1);
}
.auth-layout .language-selector .css-1y7eo0r-option {
  background-color: #2684ff !important;
  color: #fff;
}
