.faq-background {
  width: 100%;
  height: 40vh;
  background-image: url("../../assets/forms_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 70px;
}
.faq-background .faq-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../../assets/forms_overlay.png");
}
.faq-section {
  padding: 35px;
}
.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  background: #f3f3f3;
  margin-bottom: 25px;
  padding: 35px;
  gap: 15px;
}
.contact-section span,
.contact-section p {
  color: #2d2c79;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: sans-serif;
  line-height: 1.7;
}
.contact-section a {
  color: #2422bb;
  font-size: 18px;
  font-weight: 400;
  text-decoration-line: underline;
}
